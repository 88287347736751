<template>
  <div class="user-list">
    <div class="search">
      <div class="top-left">
        <Input v-model="keyword"
               :placeholder="$t('project_search_project_nameid')"
               clearable
               search
               :enter-button="true"
               @on-enter="changeKeyword"
               @on-search="changeKeyword"
        />
      </div>
    </div>
    <Table :columns="columns" :data="data"></Table>
    <div class="page">
      <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>
    </div>
  </div>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "paperList",
  data(){
    return{
      keyword:"",
      columns: [
        {
          title: 'ID',
          key: 'id',
          width:'100px',
        },
        {
          title: this.$t('dataset_user_name'),
          key: 'user.realname',
          render:(h,params)=>{
            return h('span',params.row.user.realname)
          }
        },
        {
          title: this.$t('dataset_user_nick_name'),
          key: 'user.nickname',
          render:(h,params)=>{
            return h('span',params.row.user.nickname)
          }
        },
        {
          title: this.$t('dataset_user_phone'),
          key: 'user.mobile',
          render:(h,params)=>{
            return h('span',params.row.user.mobile)
          }
        },
        {
          title: this.$t('dataset_user_create_date'),
          key: 'user.created_at',
          render:(h,params)=>{
            return h('span',util.timeFormatter(
                new Date(+params.row.user.created_at * 1000),
                'yyyy-MM-dd hh:mm'
            ))
          }
        },
        {
          title: this.$t('dataset_user_exam_time'),
          key: 'use_time',
        },
        {
          title: this.$t('dataset_user_score'),
          key: 'franction',
        }
      ],
      data: [
      ],
      categoryId:'',
      page:1,
      pageSize:10,
      total:0,
    }
  },
  created(){
    this.categoryId = this.$route.params.id;
    this.getList();
  },
  methods:{
    changeKeyword(){
      this.page = 1;
      this.getList();
    },
    getList(){
      let params = {
        category_id:this.categoryId,
        keyword:this.keyword,
        page:this.page,
        pageSize:this.pageSize
      };
      this.api.dataset.categoryUserList(params).then((res)=>{
        this.data = res.list;
        this.total = Number(res.count);
      });
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.getList();
    }
  }
}
</script>

<style scoped lang="scss">
.user-list{
  .search{
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search-l{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >div{
        margin-right: 20px;
        .tit{
          margin-right: 10px;
        }
      }
    }
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
}
</style>

<template>
  <div class="role-main">
    <div class="search-box">
      <div class="search-item">
        <div class="name">
          类型:
        </div>
        <div class="cont">
          <div class="values">
            <div :class="isToSite == item.id ? 'active' : ''" v-for="item in isToSiteList"
              :key="item.id + 'isToSite'" @click="changeSearch(item, 'isToSite')">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="margin-bottom-10 top">
      <div>
        <span style="color:#515a6e;">已选择：<strong style="color:#2d8cf0;">{{siteId.length}}</strong></span>
<!--        <Button type="primary" style="margin-left: 10px;" @click="updateSites">绑定租户</Button>-->
      </div>

      <div class="search_input">
        <Input v-model="keyword"
            @on-enter="changeKeyword"
            @on-search="changeKeyword"
            :placeholder="$t('site_search_tip')"
            clearable
            search
            :enter-button="true"
        />
      </div>
    </div>

    <Table border :columns="columns" :loading="loading" :data="data" @on-select="selectUser" @on-select-cancel="cancelUser" @on-select-all="selectUser" @on-select-all-cancel="cancelAllUser"></Table>
    <div class="page">
      <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>
    </div>

  </div>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "role",
  data(){
    return{
      page:1,
      pageSize:10,
      total:0,
      data: [],
      loading:false,
      keyword:'',
      columns: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: 'ID',
          key: 'id',
        },
        {
          title: this.$t('site_name'),
          key: 'name',
          tooltip:true
        },
        {
          title: '类型',
          key: 'status',
          align: 'center',
          //maxWidth: 180,
          //sortable: 'custom',
          render: (h, para) => {
            return h('div', {
            }, this.typesObj[para.row.type]);
          },
        },
        {
          title: '服务类型',
          key: 'status',
          align: 'center',
          //maxWidth: 180,
          //sortable: 'custom',
          render: (h, para) => {
            return h('div', {
            }, this.service_typesObj[para.row.service_type]);
          },
        },
        {
          title: this.$t('site_desc'),
          key: 'intro',
          tooltip:true,
        },
        {
          title: this.$t('site_membercount'),
          key: 'user_count',
          align: 'center',
          //maxWidth: 180,
          //sortable: 'custom',
          render: (h, para) => {
            return h('div', {
            }, para.row.user_count);
          },
        },
        {
          title: '状态',
          key: 'status',
          align: 'center',
          //maxWidth: 180,
          //sortable: 'custom',
          render: (h, para) => {
            return h('div', {
            }, this.statusObj[para.row.status]);
          },
        },
        {
          title: this.$t('user_created_at'),
          key: 'created_at',
          render: (h, para) => {
            if (para.row.created_at) {
              return h('span', util.timeFormatter(new Date(+para.row.created_at*1000), 'yyyy-MM-dd hh:mm'))
            }
          },
        },
        {
          title: this.$t('user_updated_at'),
          key: 'updated_at',
          render: (h, para) => {
            if (para.row.updated_at) {
              return h('span', util.timeFormatter(new Date(+para.row.updated_at*1000), 'yyyy-MM-dd hh:mm'))
            }
          },
        },
      ],
      statusObj:{},
      typesObj:{},
      service_typesObj:{},
      siteId:[],
      isToSiteList:[
        {
          id:"1",
          name:"已关联"
        },
        {
          id:"0",
          name:"未关联"
        }
      ],
      isToSite:"1",

    }
  },
  mounted(){
    // this.getList();
    console.warn(this.$route);
  },
  watch: {
    keyword () {
      if (!this.keyword) {
        this.changeKeyword();
      }
    },
    siteIds:{
      handler(newV){
        if(newV.length){
          this.siteId = JSON.parse(JSON.stringify(newV));
          this.getList();
        }
      },
      immediate:true
    }
  },
  props:{
    siteIds:{
      type:Array,
      default:()=>{
        return [];
      }
    }
  },

  methods:{
    // search(){
    //
    // },
    // 当分类改变时触发
    changeSearch(data, name) {
      this[name] = data.id;
      this.page = 1;
      this.getList();
    },
    changeKeyword () {
      this.page = 1;
      this.getList();
    },
    getList(){
      this.loading = true;
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        keyword: this.keyword,
        is_to_site:this.isToSite,
        category_id:this.$route.params.id||""
      };
      this.api.site.siteList(params).then((res)=>{
        this.loading = false;
        if(!res.list.length && this.page > 1){
          this.page = this.page - 1;
          this.getList();
          return;
        }
        this.data = res.list;
        this.total = Number(res.count);
        this.statusObj = res.statuses;
        this.typesObj = res.types;
        this.service_typesObj = res.service_types;


        this.data.forEach((item)=>{
          let index = this.siteId.findIndex((sItem)=>{
            return sItem == item.id;
          });
          if(index != -1){
            item._checked = true;
          }
        })
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
    selectUser(data){
      data.forEach((item)=>{
        let index = this.siteId.findIndex((sItem)=>{
          return sItem == item.id;
        });
        if(index == -1){
          this.siteId.push(item.id);
        }
      });
      this.updateSites();
    },
    cancelUser(selection,data){
      let dataIndex = this.siteId.findIndex((sItem)=>{
        return sItem == data.id;
      });
      if(dataIndex != -1){
        this.$delete(this.siteId, dataIndex);
      }
      this.updateSites();
    },
    cancelAllUser(data){
      this.data.forEach((item,index)=>{
        let dataIndex = this.siteId.findIndex((sItem)=>{
          return sItem == item.id;
        });
        if(dataIndex != -1){
          this.$delete(this.siteId, dataIndex);
        }
      })
      this.updateSites();
    },
    updateSites(){
      let params = {
        site_ids:this.siteId.join(','),
        category_id:this.$route.params.id,
      };
      this.api.dataset.datasetCategoryUpdateSites(params).then((res)=>{
        this.$Message.success('操作成功');
        this.getList();
      })
    }
  }
}
</script>

<style scoped lang="scss">
.role-main{
  //margin: 20px;
  //padding:20px;
  //background: #FFFFFF;
  //box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  //border-radius: 4px;
  margin-top: 20px;
  .search-box {
    margin-top: 20px;
    margin-bottom: 20px;

    .search-item {
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name {
        padding: 5px 0;
        text-align: right;
      }

      .cont {
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values {
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div {
            padding: 5px 10px;
            //margin: 0 10px 10px 5px;
            cursor: pointer;
          }

          >div.active {
            background-color: #2d8cf0;
            color: #FFFFFF;
            border-radius: 4px;
          }
        }

        .more {
          padding: 5px 0;
          width: 80px;
          text-align: right;
          cursor: pointer;

          .more-icon {
            transition: transform 0.3s linear;
          }
        }
      }
    }
  }
  .search{
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    >div{
      margin-right: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >span{
        margin-right: 10px;
        width: 50px;
        text-align: right;
      }
      >button{
        margin-right: 20px;
      }
    }
  }

  .margin-bottom-10{
    margin-bottom: 20px;
  }
  .top{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
}
</style>

<template>
  <div class="paper-list">
    <div class="search">
      <!--    <div class="search-l">-->
      <!--      <div>-->
      <!--        <span class="tit">题型</span>-->
      <!--        <Select v-model="type" style="width:200px">-->
      <!--          <Option v-for="item in typeList" :value="item.value" :key="item.value">{{ item.label }}</Option>-->
      <!--        </Select>-->
      <!--      </div>-->
      <!--      <div>-->
      <!--        <span class="tit">难度</span>-->
      <!--        <Select v-model="level" style="width:200px">-->
      <!--          <Option v-for="item in levelList" :value="item.value" :key="item.value">{{ item.label }}</Option>-->
      <!--        </Select>-->
      <!--      </div>-->
      <!--    </div>-->
      <div class="top-left">
        <Input v-model="keyword"
               :placeholder="$t('project_search_project_nameid')"
               clearable
               search
               :enter-button="true"
               @on-enter="changeKeyword"
               @on-search="changeKeyword"
        />
      </div>
      <Button type="primary" @click="copy">导入其他分类数据(仅关联关系)</Button>
    </div>
    <Table :columns="columns" :data="data"></Table>
    <div class="page">
      <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>
    </div>
    <Modal
        v-model="categoryModal"
        title="选择分类"
        width="800px"
    >

      <div class="search">
        <div class="search-item">
          <div class="name">
            一级分类:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="curCategoryId == item.id ? 'active' : ''" v-for="item in categoryList" :key="item.id + 'category'" @click="changeSearch(item,'curCategoryId')">{{item.name}}</div>
            </div>
          </div>
        </div>
        <div class="search-item">
          <div class="name">
            二级分类:
          </div>
          <div class="cont">
            <div class="values" >
              <div :class="cursecondCategoryId == item.id ? 'active' : ''" v-for="item in secondCategoryList" :key="item.id + 'secondCategory'" @click="changeSearch(item,'cursecondCategoryId')">{{item.name}}</div>
            </div>
          </div>
        </div>
        <div class="search-item">
          <div class="name">
            三级分类:
          </div>
          <div class="cont">
            <div class="values" >
              <div :class="curThirdCategoryId == item.id ? 'active' : ''" v-for="item in thirdCategoryList" :key="item.id + 'thirdCategory'" @click="changeSearch(item,'curThirdCategoryId')">{{item.name}}</div>
            </div>
          </div>
        </div>
        <div class="search-item">
          <div class="name">
            题目数量:
          </div>
          <div class="cont">
            <div class="values" ><div>{{thirdCategoryNum}}</div></div>
          </div>
        </div>
      </div>
      <div slot="footer">
        <Button @click="categoryModal=false">{{ $t('dataset_map_create_cancel') }}</Button>
        <Button type="primary" @click="confirm">{{ $t('dataset_map_create_confirm') }}</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "paperList",
  data(){
    return{
      keyword:"",
      columns: [
        {
          title: 'ID',
          key: 'id',
          width:'100px',
        },
        {
          title: this.$t('dataset_category_exam_name'),
          key: 'data.title',
          render:(h,params)=>{
            return h('span',params.row.data.title)
          }
        },
        {
          title: this.$t('dataset_category_analysis'),
          key: 'data.analysis',
          render:(h,params)=>{
            return h('span',params.row.data.analysis)
          }
        },
        // {
        //   title: this.$t('dataset_category_correct'),
        //   key: 'data.rightkey',
        //   width:'100px',
        //   render:(h,params)=>{
        //     return h('span',params.row.data.rightkey)
        //   }
        // }
      ],
      data: [
      ],
      categoryId:'',
      page:1,
      pageSize:10,
      total:0,
      categoryList:[],  //一级分类
      curCategoryId:'-1', //当前分类
      secondCategoryList:[],  //二级分类
      cursecondCategoryId:'-1', //当前二级分类
      curThirdCategoryId:'-1',//当前三级分类
      thirdCategoryList:[],
      categoryModal:false,
      thirdCategoryNum:0,
    }
  },
  created(){
    this.categoryId = this.$route.params.id;
    this.getList();
    this.getCategoryList();
  },
  methods:{
    changeKeyword(){
      this.page = 1;
      this.getList();
    },
    getList(){
      let params = {
        category_id:this.categoryId,
        keyword:this.keyword,
        page:this.page,
        pageSize:this.pageSize
      };
      this.api.dataset.categoryDataList(params).then((res)=>{
        this.data = res.list;
        this.total = Number(res.count);
      });
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.getList();
    },
    changeSearch(data,name){

      this[name] = data.id;
      if(name == 'curCategoryId'){
        this.cursecondCategoryId = '';
        this.curThirdCategoryId = ''
        this.thirdCategoryNum = 0;
        this.getSecondCategoryList();
      }else if(name == 'cursecondCategoryId'){
        this.curThirdCategoryId = ''
        this.thirdCategoryNum = 0;
        this.getThirdCategoryList();
      }else if(name == 'curThirdCategoryId'){
        this.thirdCategoryNum = data.data_count;
      }
    },
    getCategoryList(){
      let data = {
        page:1,
        pageSize:10,
        category_id:this.curCategoryId,
        category_second_id:this.cursecondCategoryId,
        site_id:-1,
        user_id:-1,
      };

      this.api.dataset.categoryForm(data).then((res)=>{

        //一级标签
        this.categoryList = [
          ...res.categoryFirstList
        ];
        this.curCategoryId = this.categoryList[0].id;

        this.getSecondCategoryList();

      })
    },
    getSecondCategoryList(){
      let data = {
        page:1,
        pageSize:10,
        category_id:this.curCategoryId,
        site_id:-1,
        user_id:-1,
      };

      this.api.dataset.categoryForm(data).then((res)=>{

        //二级标签
        this.secondCategoryList = [
          ...res.categorySecondList
        ];
        if(this.secondCategoryList.length){
          this.cursecondCategoryId = this.secondCategoryList[0].id;
          this.getThirdCategoryList();
        }


      })
    },
    getThirdCategoryList(){
      let data = {
        page:1,
        pageSize:10,
        category_id:this.curCategoryId,
        category_second_id:this.cursecondCategoryId,
        site_id:-1,
        user_id:-1,
      };

      this.api.dataset.categoryForm(data).then((res)=>{

        this.thirdCategoryList = [
          ...res.categoryThirdList
        ]
        if(this.thirdCategoryList.length){
          this.curThirdCategoryId = this.thirdCategoryList[0].id
          this.thirdCategoryNum = this.thirdCategoryList[0].data_count;
        }


      })
    },
    confirm(){
      console.log(this.curThirdCategoryId,'this.curThirdCategoryId')
      if(!this.curThirdCategoryId) return;
      let params = {
        category_id:this.categoryId,
        from_category_id:this.curThirdCategoryId
      };
      this.api.dataset.datasetCategoryCopyDataList(params).then((res)=>{
        this.$Message.success('操作成功');
        this.page = 1;
        this.getList();
        this.categoryModal = false;
      })
    },
    copy(){
      this.categoryModal = true;
    }
  }
}
</script>

<style scoped lang="scss">
.paper-list{
  .search{
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search-l{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >div{
        margin-right: 20px;
        .tit{
          margin-right: 10px;
        }
      }
    }
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
}
.search{
  margin-top: 20px;
  margin-bottom: 20px;
  .search-item{
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    font-size: 14px;

    .name{
      padding: 5px 0;
      width: 75px;
      text-align: right;
    }
    .cont{
      margin-left: 15px;
      flex: 1;
      display: flex;
      justify-content: space-between;

      .values{
        display: flex;
        justify-content: flex-start;
        flex-flow: wrap;
        transition: max-height 0.3s linear;
        overflow: hidden;

        >div{
          padding: 5px 10px;
          //margin: 0 10px 10px 5px;
          cursor: pointer;
        }
        >div.active{
          background-color: #2d8cf0;
          color:#FFFFFF;
          border-radius: 4px;
        }
      }
      .more{
        padding: 5px 0;
        width: 80px;
        text-align: right;
        cursor: pointer;
        .more-icon{
          transition: transform 0.3s linear;
        }
      }
    }
  }
}
</style>

<template>
  <div class="role-main">
    <div class="search">
      <div class="search-item">
        <div class="name">
          一级分类:
        </div>
        <div class="cont">
          <div class="values">
            <div :class="curCategoryId == item.id ? 'active' : ''" v-for="item in categoryList" :key="item.id + 'category'" @click="changeSearch(item,'curCategoryId')">{{item.name}}</div>
          </div>
        </div>
      </div>
      <div class="search-item" v-if="secondCategoryList.length > 1">
        <div class="name">
          二级分类:
        </div>
        <div class="cont">
          <div class="values" >
            <div :class="cursecondCategoryId == item.id ? 'active' : ''" v-for="item in secondCategoryList" :key="item.id + 'secondCategory'" @click="changeSearch(item,'cursecondCategoryId')">{{item.name}}</div>
          </div>
        </div>
      </div>
    </div>
    <Table border :columns="columns" :loading="loading" :data="data" @on-select="selectUser" @on-select-cancel="cancelUser" @on-select-all="selectUser" @on-select-all-cancel="cancelAllUser"></Table>
    <div class="page">
      <Page :total="total" @on-change="changePage" :current="page" show-total show-sizer show-elevator></Page>
    </div>

  </div>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "role",
  data(){
    return{
      columns: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: this.$t('dataset_category_table_index'),
          key:'id',
        },
        {
          title: this.$t('dataset_category_table_name'),
          key: 'name',
        },
        {
          title: this.$t('dataset_category_table_data_count'),
          key: 'data_count',
        },
        {
          title: '状态',
          key: 'desc',
          render: (h, para) => {
            return h('span', this.statusObj[para.row.status]);
          }
        },
        {
          title: '排序',
          key: 'sort',
        },
        {
          title: this.$t('dataset_category_table_desc'),
          key: 'desc',
        },
        {
          title: this.$t('dataset_data_table_created_at'),
          key: 'created_at',
          render: (h, para) => {
            return h('span', util.timeFormatter(
                    new Date(+para.row.created_at * 1000), 'MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: this.$t('dataset_data_table_updated_at'),
          key: 'updated_at',
          render: (h, para) => {
            return h('span', util.timeFormatter(
                    new Date(+para.row.updated_at * 1000), 'MM-dd hh:mm'
                )
            );
          }
        },
      ],
      data: [],
      loading:false,
      page:1,
      pageSize:10,
      total:0,
      addBtnLoading:false,
      type:'',
      trainSetting:false,
      categoryList:[],
      curCategoryId:'-1',
      secondCategoryList:[],
      cursecondCategoryId:'-1',
      statusObj:{},
      statusList:[],
      childId:[],
    }
  },
  watch: {
    childIds:{
      handler(newV){
        if(newV.length){
          this.childId = JSON.parse(JSON.stringify(newV));
          this.getList();
        }
      },
      immediate:true
    }
  },
  props:{
    childIds:{
      type:Array,
      default:()=>{
        return [];
      }
    }
  },
  mounted(){
    this.getList();
  },
  methods:{
    changeSearch(data,name){
      if(name == 'curCategoryId'){
        this.cursecondCategoryId = '-1';
      }
      this[name] = data.id;
      this.page = 1;
      this.getList();
    },
    getList(){
      this.loading = true;
      let params = {
        site_id:-1,
        user_id:-1,
        page:this.page,
        pageSize:this.pageSize,
        category_id:this.curCategoryId,
        second_category_id:this.cursecondCategoryId,
      };
      this.api.dataset.categoryList(params).then((res)=>{
        this.loading = false;
        if(!res.list.length && this.page > 1){
          this.page = this.page - 1;
          this.getList();
          return;
        }
        this.data = res.list;
        this.total = Number(res.count);
        this.categoryList = [
          {
            name:'全部',
            id:'-1'
          },
          ...res.categoryFirstList
        ];
        this.secondCategoryList = [
          {
            name:'全部',
            id:'-1'
          },
          ...res.categorySecondList
        ];
        this.statusObj = res.statuses;
        this.statusList = [];
        for(let name in res.statuses){
          this.statusList.push({
            name:res.statuses[name],
            id:name
          })
        }

        this.data.forEach((item)=>{
          let index = this.childId.findIndex((sItem)=>{
            return sItem == item.id;
          });
          if(index != -1){
            item._checked = true;
          }
        })
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changePage(page){
      console.log(page,'changePage')
      this.page = page;
      this.getList();
    },
    selectUser(data){
      data.forEach((item)=>{
        let index = this.childId.findIndex((sItem)=>{
          return sItem == item.id;
        });
        if(index == -1){
          this.childId.push(item.id);
        }
      });
      this.updateCategory();
    },
    cancelUser(selection,data){
      let dataIndex = this.childId.findIndex((sItem)=>{
        return sItem == data.id;
      });
      if(dataIndex != -1){
        this.$delete(this.childId, dataIndex);
      }
      this.updateCategory();
    },
    cancelAllUser(data){
      this.data.forEach((item,index)=>{
        let dataIndex = this.childId.findIndex((sItem)=>{
          return sItem == item.id;
        });
        if(dataIndex != -1){
          this.$delete(this.childId, dataIndex);
        }
      })
      this.updateCategory();
    },
    updateCategory(){
      let params = {
        child_ids:this.childId.join(','),
        category_id:this.$route.params.id,
      };
      this.api.dataset.datasetCategoryUpdateChildren(params).then((res)=>{
        this.$Message.success('更新子分类成功');
        this.getList();
      })
    }
  }
}
</script>

<style scoped lang="scss">
.role-main{
  .search{
    margin-top: 20px;
    margin-bottom: 20px;
    .search-item{
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name{
        padding: 5px 0;
        width: 75px;
        text-align: right;
      }
      .cont{
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div{
            padding: 5px 10px;
            //margin: 0 10px 10px 5px;
            cursor: pointer;
          }
          >div.active{
            background-color: #2d8cf0;
            color:#FFFFFF;
            border-radius: 4px;
          }
        }
        .more{
          padding: 5px 0;
          width: 80px;
          text-align: right;
          cursor: pointer;
          .more-icon{
            transition: transform 0.3s linear;
          }
        }
      }
    }
  }
  .top{
    text-align: left;
    margin-bottom: 20px;
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
}
.brush-question{
  .tit{
    font-size: 16px;
    font-weight: bold;
  }
  .list{
    margin: 20px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    >span{
      margin-right: 10px;
      padding: 5px 20px;
      display: inline-block;
      background-color: #e8eaec;
      border-radius: 4px;
      cursor: pointer;
    }
    >span.active{
      border: 1px solid #2d8cf0;
      background-color: #2d8cf0;
      color:#FFFFFF;
    }
  }
}
</style>

<template>
  <div class="test-info">
    <div class="layout-content" >
      <div v-if="!loading">
        <Row>
          <i-col span="2">
            <!--            <img v-if="siteDetail.logo" :src="siteDetail.logo">-->
            <div class="head-img">
              <p>{{categoryDeatil.name && categoryDeatil.name[0].toUpperCase()}}</p>
              <!--            <img v-if="taskDeatil.project.category" :src="constant.STATIC_URL + taskDeatil.project.category.thumbnail" style="width: 70%;"/>-->
            </div>
          </i-col>
          <i-col span="22">
            <Row>
              <i-col span="24" class="project-detail-info">
                <div class="info-item" >
                  <strong style="font-size:16px; max-width: 600px; display: inline-block;padding-left: 10px;">{{ categoryDeatil.name }}</strong>
                </div>
              </i-col>
            </Row>
            <Row :gutter="20" style="font-size: 14px;margin: 10px 0;">
              <i-col span="6">ID :   {{categoryDeatil.id}}</i-col>
              <i-col span="6">{{ $t('dataset_category_num') }} :  {{categoryDeatil.data_count}}</i-col>
              <i-col span="6">{{ $t('dataset_category_create_date') }} :  {{categoryDeatil.created_at}}</i-col>
              <i-col span="6">{{ $t('dataset_category_update_date') }} :   {{categoryDeatil.updated_at}}</i-col>

            </Row>
            <Row :gutter="20" style="font-size: 14px;margin: 10px 0;">
              <i-col span="6">{{ $t('dataset_category_remark') }} :   </i-col>

            </Row>
          </i-col>
        </Row>
      </div>
      <Spin fix v-else></Spin>
    </div>
    <div class="cont">
      <div class="cont-top">
        <div class="tab">
          <div :class="curTab.id == item.id ? 'active' : ''" v-for="item in tabList" :key="item.id" @click="changeTab(item)">{{ item.name }}</div>
        </div>
      </div>
      <div class="cont-item">
        <component :is="curTab.componentName" :siteIds="siteIds" :childIds="childIds"></component>
      </div>
    </div>
  </div>
</template>

<script>
import util from '@/utils/tools.js';
import PaperList from './components/category/paperList'
import UserList from './components/category/userList'
import SiteManage from './components/category/siteManage'
import RelationCategory from './components/category/relationCategory'

export default {
  name: "test-info",
  data(){
    return{
      categoryDeatil:{},
      categoryId:'',
      loading:false,
      typesObj:{},
      tabList:[
        {
          name:this.$t('dataset_category_questions'),
          id:1,
          componentName:'PaperList',
          tabName:'list'
        },
        {
          name:this.$t('dataset_category_users'),
          id:2,
          componentName:'UserList',
          tabName:'userList'
        },
        {
          name:'关联租户',
          id:3,
          componentName:'SiteManage',
          tabName:'siteManage'
        },
        // {
        //   name:'更新下级子分类',
        //   id:4,
        //   componentName:'RelationCategory',
        //   tabName:'relationCategory'
        // }
      ],
      curTab:{
        name:this.$t('dataset_category_questions'),
        id:1,
        componentName:'PaperList',
        tabName:'list'
      },
      siteIds:[],
      childIds:[],
    }
  },
  components:{
    PaperList,
    UserList,
    SiteManage,
    RelationCategory
  },
  created(){
    this.curTab = this.tabList.filter((item)=>{
      return item.tabName == this.$route.params.tab;
    })[0];
    this.categoryId = this.$route.params.id;
    this.getDetail()
  },
  methods:{
    getDetail(){
      let params = {
        id:this.categoryId
      };
      this.loading = true;
      this.api.dataset.categoryDetail(params).then((res)=>{
        console.log('22323')
        this.loading = false;
        this.categoryDeatil = res.info;
        this.typesObj = res.types;

        // this.examDeatil.batch.name = util.decrypt(this.examDeatil.batch.name);

        this.categoryDeatil.created_at = util.timeFormatter(
            new Date(+res.info.created_at * 1000),
            'yyyy-MM-dd'
        );
        this.categoryDeatil.updated_at = util.timeFormatter(
            new Date(+res.info.updated_at * 1000),
            'yyyy-MM-dd'
        );
        this.siteIds = [];
        for(let name in res.siteIds){
          this.siteIds.push(res.siteIds[name])
        }
        this.childIds = [];
        for(let name in res.childIds){
          this.childIds.push(res.childIds[name])
        }

      }).catch((err)=>{
        this.loading = false;
      })
    },
    changeTab(data){
      this.curTab = data;
      this.$router.push({
        name: 'category-detail',
        params: {
          id: this.$route.params.id,
          tab: data.tabName,
        }
      });
    },
  }
}
</script>

<style scoped lang="scss">
.test-info{
  .layout-content{
    margin: 20px;
    padding: 20px;
    background: #ffffff;
    min-height: 100px;
    border-radius: 4px;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    position: relative;

    .project-detail-info {
      display: flex;
      justify-content: space-between;
    }
    .head-img{
      width: 80px;
      height: 80px;
      border-radius: 80px;
      line-height: 80px;
      background-color: #ccc;
      text-align: center;
      font-size: 55px;
      color:#FFFFFF;
      >img{
        width: 100%;
        height: 100%;
        border-radius: 80px;
      }
    }
    .project-detail-info .info-item button {
      margin-bottom: 5px;
    }
    .project-detail-info .info-item button + button {
      margin-left: 5px;
    }
    .info-item{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .tit-tag{
        padding: 5px 10px;
        text-align: center;
        color:#FFFFFF;
        border-radius: 4px;
        cursor: pointer;
        background-color:#2d8cf0 ;
      }
    }
    .progress{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >span{
        width: 60px;
      }
    }
  }
  .cont{
    margin: 0 20px 20px 20px;
    padding: 20px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    .cont-top{
      display: flex;

    }
    .tab{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border:1px solid #dcdee2;
      text-align: center;
      height: 46px;
      border-radius: 4px;
      overflow: hidden;
      font-size: 14px;
      cursor: default;
      line-height: 46px;

      >div{
        width: 150px;
        border-right:1px solid #dcdee2;
        cursor: pointer;
        height: 100%;
      }
      >div:last-child{
        border-right:none;
      }
      >div.active{
        background-color: #2d8cf0;
        color:#FFFFFF;
      }
    }
  }
}
</style>
